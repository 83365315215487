<template>
  <v-alert text prominent type="error" v-if="error" icon="$vuetify.icons.alert">
    {{ getText(error) }}
  </v-alert>
</template>

<script>
import { responseHelpers } from "@/_helpers";

export default {
  name: "InlineError",
  props: ["error"],
  methods: {
    getText(error) {
      return responseHelpers.errorTextFromResponse(error);
    },
  },
};
</script>