<template>
  <v-app-bar
    app
    clipped-left
    clipped-right
    color="#457b9d"
    dark
    class="top-bar"
  >
    <v-btn to="/" class="text-none logo-font logo-top" text>
      <v-icon left class="mr-4" size="28"
        >$vuetify.icons.bookOpenPageVariantOutline</v-icon
      >
      {{ $t("PageName") }}
    </v-btn>

    <v-spacer></v-spacer>

    <template v-if="!user">
      <v-btn to="/" text class="text-none">
        {{ $t("Home") }}
      </v-btn>
      <v-btn to="/signup" text class="text-none">
        {{ $t("SignUp") }}
      </v-btn>
      <v-btn to="/login" text class="text-none">
        {{ $t("Login") }}
      </v-btn>
    </template>

    <template v-if="user">
      <v-tooltip bottom>
        <template v-slot:activator="{ on: onTooltip }">
          <v-btn
            color="#2C6284"
            depressed
            to="/"
            v-on="{ ...onTooltip }"
            fab
            small
            class="mr-4 lighten-1"
          >
            <v-icon>$vuetify.icons.fasHome</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("Home") }}</span>
      </v-tooltip>
      <diaries-menu v-if="show_diaries"></diaries-menu>
      <message-menu></message-menu>
      <notification-menu></notification-menu>
      <account-menu></account-menu>
    </template>
  </v-app-bar>
</template>

<i18n>
    {
    "en": {
    "SignUp": "Sign up",
    "Login": "Login",
    "PageName": "Babiry",
    "Home": "Home",
    "Account": "My account"
    },
    "sv": {
    "SignUp": "Bli medlem",
    "Login": "Logga in",
    "PageName": "Barndagboken",
    "Home": "Startsida",
    "Account": "Mitt konto"
    }
    }
</i18n>

<script>
import { mapState } from "vuex";
import NotificationMenu from "../topmenu/NotificationMenu.vue";
import MessageMenu from "../topmenu/MessageMenu.vue";
import AccountMenu from "../topmenu/AccountMenu.vue";
import MyDiariesMenu from "../topmenu/MyDiariesMenu.vue";

export default {
  name: "MainBar",
  components: {
    "notification-menu": NotificationMenu,
    "message-menu": MessageMenu,
    "account-menu": AccountMenu,
    "diaries-menu": MyDiariesMenu,
  },
  computed: {
    ...mapState({
      user: (state) => state.account.user,
    }),
    show_diaries: function () {
      if(!this.user) return false;

      if (this.user.diaries == null || this.user.diaries.length == 0)
        return true;

      if (this.user.diaries.length > 1) return true;

      if (this.user.diaries.length == 1 && this.user.diaries[0].roles.length > 0) {
        if (this.user.diaries[0].roles.indexOf(1) == -1) return true;
      }

      return false;
    },
  },
};
</script>

<style scoped>
.logo-font.v-btn--active::before {
  opacity: 1;
  background-color: transparent !important;
}
</style>