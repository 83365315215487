<template>
  <span v-if="user">
    <v-menu
      close-on-click
      close-on-content-click
      :nudge-bottom="$nudgeTop"
      offset-y
      v-if="!$isMobile"
    >
      <template v-slot:activator="{ on: onMenu, attrs }">
        <v-tooltip bottom :disabled="$isMobile">
          <template v-slot:activator="{ on: onTooltip }">
            <v-btn
              color="#2C6284"
              depressed
              v-on="{ ...onMenu, ...onTooltip }"
              v-bind="attrs"
              fab
              small
            >
              <v-icon>$vuetify.icons.fasUser</v-icon>
            </v-btn>
          </template>
          <span>{{ $t("Account") }}</span>
        </v-tooltip>
      </template>
      <v-card width="350" tile class="top-bar-dropdown">
        <v-list two-line>
          <v-list-item :to="'/user/' + user.username">
            <v-list-item-avatar>
              <user-avatar :user="user" :size="40"></user-avatar>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title class="subtitle-1 font-weight-medium pb-1">{{
                user.username | capitalize
              }}</v-list-item-title>
              <v-list-item-subtitle>{{
                $t("ShowOwnProfile")
              }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-divider></v-divider>
        <v-list two-line class="admin-list-menu">
          <v-list-item @click="openFeedback">
            <v-list-item-avatar>
              <v-icon class="grey lighten-2">$vuetify.icons.fasComment</v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>{{ $t("Feedback") }}</v-list-item-title>
              <v-list-item-subtitle>{{
                $t("FeedbackDesc")
              }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-divider></v-divider>

        <v-list>
          <v-list-item to="/account/following" exact>
            <v-list-item-avatar>
              <v-icon class="grey lighten-2">$vuetify.icons.fasStar</v-icon>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title>{{ $t("Following") }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item to="/account" exact>
            <v-list-item-avatar>
              <v-icon class="grey lighten-2">$vuetify.icons.fasCog</v-icon>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title>{{ $t("Settings") }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item to="/account/logout" exact>
            <v-list-item-avatar>
              <v-icon class="grey lighten-2">$vuetify.icons.fasSignOutAlt</v-icon>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title>{{ $t("Logout") }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card>
    </v-menu>

    <v-bottom-sheet v-model="bottom_menu" v-if="$isMobile">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="#2C6284"
          depressed
          v-on="{ ...on, ...attrs }"
          v-bind="attrs"
          fab
          small
        >
          <v-icon>$vuetify.icons.fasUser</v-icon>
        </v-btn>
      </template>
      <v-list two-line>
        <v-list-item :to="'/user/' + user.username" @click="bottom_menu = false">
          <v-list-item-avatar>
            <user-avatar :user="user" :size="40"></user-avatar>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title class="subtitle-1 font-weight-medium pb-1">{{
              user.username | capitalize
            }}</v-list-item-title>
            <v-list-item-subtitle>{{
              $t("ShowOwnProfile")
            }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-divider></v-divider>
      <v-list two-line class="admin-list-menu">
        <v-list-item @click="openFeedback">
          <v-list-item-avatar>
            <v-icon class="grey lighten-2">$vuetify.icons.fasComment</v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>{{ $t("Feedback") }}</v-list-item-title>
            <v-list-item-subtitle>{{
              $t("FeedbackDesc")
            }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-divider></v-divider>

      <v-list>
        <v-list-item to="/account/following" exact @click="bottom_menu = false">
          <v-list-item-avatar>
            <v-icon class="grey lighten-2">$vuetify.icons.fasStar</v-icon>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title>{{ $t("Following") }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item to="/account" exact @click="bottom_menu = false">
          <v-list-item-avatar>
            <v-icon class="grey lighten-2">$vuetify.icons.fasCog</v-icon>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title>{{ $t("Settings") }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item to="/account/logout" exact @click="bottom_menu = false">
          <v-list-item-avatar>
            <v-icon class="grey lighten-2">$vuetify.icons.fasSignOutAlt</v-icon>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title>{{ $t("Logout") }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-bottom-sheet>

    <feedback-dialog ref="feedbackDialog"></feedback-dialog>
  </span>
</template>

<i18n>
    {
    "en": {
      "Account": "Account",
      "Settings": "Account settings",
      "Logout": "Sign out",
      "ShowOwnProfile": "Show my profile",
      "Feedback": "Give feedback",
      "FeedbackDesc": "Help us improve Babiry",
      "Following": "Following"
    },
    "sv": {
      "Account": "Konto",
      "Settings": "Kontoinställningar",
      "Logout": "Logga ut",
      "ShowOwnProfile": "Visa din profil",
      "Feedback": "Ge feedback",
      "FeedbackDesc": "Hjälp oss förbättra Barndagboken",
      "Following": "Följer"
    }
    }
</i18n>

<script>
import { mapState } from "vuex";
import FeedbackDialog from "../../components/dialogs/FeedbackDialog.vue";
import UserAvatar from "@/components/avatars/UserAvatar.vue";
export default {
  name: "AccountMenuBar",

  data: () => ({
    bottom_menu: false,
  }),
  computed: {
    ...mapState("account", ["user"]),
  },
  components: {
    "feedback-dialog": FeedbackDialog,
    "user-avatar": UserAvatar,
  },
  methods: {
    openFeedback() {
      this.bottom_menu = false;
      this.$refs.feedbackDialog.open();
    },
  },
};
</script>