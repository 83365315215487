<template>
  <div>
    <v-btn
      color="#2C6284"
      depressed
      fab
      small
      class="mr-4"
      v-if="$isMobile"
      to="/account/messages"
    >
      <v-badge
        color="red"
        overlap
        :content="formated_unit_unread_pm"
        v-model="show_message_badget"
      >
        <v-icon>$vuetify.icons.fasComment</v-icon>
      </v-badge>
    </v-btn>

    <v-menu
      close-on-click
      close-on-content-click
      v-model="is_open"
      :nudge-bottom="$nudgeTop"
      offset-y
      v-if="!$isMobile"
    >
      <template v-slot:activator="{ on: onMenu, attrs }">
        <v-tooltip bottom :disabled="$isMobile">
          <template v-slot:activator="{ on: onTooltip }">
            <v-btn
              color="#2C6284"
              depressed
              v-on="{ ...onMenu, ...onTooltip }"
              v-bind="attrs"
              fab
              small
              class="mr-4"
            >
              <v-badge
                color="red"
                overlap
                :content="formated_unit_unread_pm"
                v-model="show_message_badget"
              >
                <v-icon>$vuetify.icons.fasComment</v-icon>
              </v-badge>
            </v-btn>
          </template>
          <span>{{ $t("Messages") }}</span>
        </v-tooltip>
      </template>
      <v-card width="400" class="top-bar-dropdown">
        <message-menu ref="messagesList"></message-menu>
      </v-card>
    </v-menu>
  </div>
</template>

<i18n>
    {
    "en": {
    "NewMessage": "New messages!",
    "Messages": "Messages"
    },
    "sv": {
    "NewMessage": "Nytt meddelande!",
    "Messages": "Meddelanden"
    }
    }
</i18n>



<script>
import { mapState } from "vuex";
import MessageMenuList from "./MessageMenuList.vue";

export default {
  data: () => ({
    formated_unit_unread_pm: 0,
    show_message_badget: false,

    is_open: false,
  }),
  components: {
    "message-menu": MessageMenuList,
  },
  computed: {
    ...mapState({
      user: (state) => state.account.user,
    }),
  },
  created() {
    if (this.user) {
      this.formatUnreadPm(this.user.unit_unread_pm);
    }
  },
  methods: {
    formatUnreadPm(unit) {
      this.show_message_badget = true;

      if (!unit || unit < 1) {
        this.show_message_badget = false;
        this.formated_unit_unread_pm = 0;
      } else if (unit > 99) {
        this.formated_unit_unread_pm = "99+";
      } else {
        this.formated_unit_unread_pm = unit;
      }
    },
  },
  watch: {
    is_open: function (newVal) {
      if (newVal && this.$refs.messagesList) {
        this.$refs.messagesList.load();
      }
    },
    "user.unit_unread_pm": function (newVal, oldVal) {
      this.formatUnreadPm(newVal);

      if (newVal > oldVal) {
        this.$toast.info(this.$t("NewMessage"), { icon: "$vuetify.icons.fasComment" });

        this.$store.dispatch("account/messages/hasNewData", null, {
          root: true,
        });
      }
    },
  },
};
</script>