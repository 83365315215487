<template>
  <span>
    <v-menu offset-y v-if="!$isMobile && mobileMode" :disabled="disabled">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          :depressed="depressed"
          :icon="icon"
          :class="btnClass"
          v-bind="attrs"
          v-on="on"
          class="text-none"
          :disabled="disabled"
          :loading="isLoading"
          :right="right"
          :top="top"
          :absolute="absolute"
          :fab="fab"
          :small="small"
          :color="color"
          :style="btnStyle"
          :x-small="xSmall"
          :dark="dark"
          :outlined="outlined"
          :text="text"
        >
          <v-icon
            :small="btnIconSmall"
            :left="btnText != null"
            v-if="btnIcon"
            >{{ btnIcon }}</v-icon
          >
          {{ btnText }}

          <v-icon :small="btnIconSmall" right v-if="btnIconAppend">{{
            btnIconAppend
          }}</v-icon>
        </v-btn>
      </template>
      <v-card class="button-menu-card" :class="menuClass" elevation="1">
        <v-list class="admin-list-menu">
          <template v-if="subheaderText">
            <v-subheader class="sub-header">{{ subheaderText }}</v-subheader>
            <v-divider></v-divider>
          </template>
          <div class="button-menu-card-container">
            <v-list-item
              :to="item.to"
              v-for="(item, i) in items"
              :key="i"
              @click="menuClick(item)"
              :exact="item.exact"
            >
              <v-list-item-icon v-if="item.icon">
                <v-icon size="20">{{ item.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ item.name }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </div>
        </v-list>
      </v-card>
    </v-menu>

    <template v-if="$isMobile && mobileMode">
      <v-btn
        @click="sheet = true"
        :depressed="depressed"
        :icon="icon"
        :class="btnClass"
        class="text-none"
        :disabled="disabled"
        :loading="isLoading"
        :right="right"
        :top="top"
        :absolute="absolute"
        :fab="fab"
        :small="small"
        :color="color"
        :style="btnStyle"
        :x-small="xSmall"
        :dark="dark"
        :outlined="outlined"
        :text="text"
      >
        <v-icon :small="btnIconSmall" v-if="btnIcon" :left="btnText != null">{{
          btnIcon
        }}</v-icon>
        {{ btnText }}
        <v-icon :small="btnIconSmall" right v-if="btnIconAppend">{{
          btnIconAppend
        }}</v-icon>
      </v-btn>
      <v-bottom-sheet v-model="sheet">
        <v-list
          :subheader="mobileSubheader || subheaderText"
          class="mobile-bottom-menu"
        >
          <v-subheader v-if="mobileSubheader && !subheaderText && btnText">{{
            btnText
          }}</v-subheader>
          <v-subheader v-if="subheaderText">{{ subheaderText }}</v-subheader>
          <v-list-item
            :to="item.to"
            v-for="(item, i) in items"
            :key="i"
            @click="menuClick(item)"
            :exact="item.exact"
          >
            <v-list-item-icon v-if="item.icon">
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{
                item.name
              }}</v-list-item-title>
              <div class="caption" v-if="item.description">{{ item.description }}</div>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-bottom-sheet>
    </template>
  </span>
</template>

<script>
export default {
  name: "ButtonMenu",
  props: {
    items: {
      type: Array,
      required: true,
    },
    btnClass: {
      type: String,
      default: "",
    },
    depressed: {
      type: Boolean,
      default: true,
    },
    btnIconSmall: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: Boolean,
      default: false,
    },
    btnText: {
      type: String,
      default: null,
    },
    btnIcon: {
      type: String,
      default: "",
    },
    btnIconAppend: {
      type: String,
      default: null,
    },
    mobileSubheader: {
      type: Boolean,
      default: true,
    },
    mobileMode: {
      type: Boolean,
      default: true,
    },
    mobileScrollTo: {
      type: String,
      default: null,
    },
    subheaderText: {
      type: String,
      default: null,
    },
    menuClass: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    right: {
      type: Boolean,
      default: false,
    },
    top: {
      type: Boolean,
      default: false,
    },
    absolute: {
      type: Boolean,
      default: false,
    },
    fab: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
    xSmall: {
      type: Boolean,
      default: false,
    },
    dark: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: "",
    },
    btnStyle: {
      type: String,
      default: "",
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    text: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    sheet: null,
  }),
  methods: {
    menuClick(item) {
      this.sheet = false;

      if (item.click) item.click.call();

      if (this.mobileScrollTo) this.$vuetify.goTo(this.mobileScrollTo);
    },
  },
};
</script>


<style scoped>
.sub-header {
  padding-top: 2px !important;
  padding-bottom: 5px !important;
  font-weight: bold !important;
  height: auto !important;
}


.mobile-bottom-menu .v-list-item__icon:first-child {
margin-right: 15px;
}
</style>