import { render, staticRenderFns } from "./MessageMenuList.vue?vue&type=template&id=3ec6ba08&scoped=true&"
import script from "./MessageMenuList.vue?vue&type=script&lang=js&"
export * from "./MessageMenuList.vue?vue&type=script&lang=js&"
import style0 from "./MessageMenuList.vue?vue&type=style&index=0&id=3ec6ba08&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3ec6ba08",
  null
  
)

/* custom blocks */
import block0 from "./MessageMenuList.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VSubheader } from 'vuetify/lib/components/VSubheader';
installComponents(component, {VDivider,VList,VListItem,VListItemAvatar,VListItemContent,VListItemSubtitle,VListItemTitle,VSkeletonLoader,VSpacer,VSubheader})
