<template>
  <v-list two-line>
    <v-subheader class="sub-header">
      {{ $t("MyDiaries") }}
      <v-spacer></v-spacer>
      <router-link
        v-if="show_create"
        style="text-decoration: none"
        :to="{ name: 'diary_create' }"
        @click="$emit('click')"
        >{{ $t("CreateDiary") }}</router-link
      >
    </v-subheader>
    <v-divider></v-divider>

    <div class="message-container notification-list">
      <template v-for="(blog, index) in user.diaries">
        <v-list-item
          :key="blog.id"
          :to="'/' + blog.name"
          @click="$emit('click')"
        >
          <v-list-item-avatar>
            <blog-avatar :diary="blog" :size="40"></blog-avatar>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title class="global-list-header">{{
              blog.title | capitalize
            }}</v-list-item-title>
            <v-list-item-subtitle class="global-list-desc">{{
              getRole(blog.roles)
            }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-divider :key="index" v-if="index < user.diaries.length"></v-divider>
      </template>
      <v-list-item
        v-if="user.diaries == null || user.diaries.length == 0"
        :to="{ name: 'diary_create' }"
      >
        <v-list-item-content>
          <v-list-item-title class="global-list-header">{{
            $t("NoDiary")
          }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </div>
  </v-list>
</template>

<i18n>
    {
    "en": {
      "MyDiaries": "My diaries",
      "Owner": "Owner",
      "Admin": "Administrator",
      "Reader": "Reader",
      "CreateDiary": "Create own diary",
      "NoDiary": "You have no diary! Create one for free!"
    },
    "sv": {
      "MyDiaries": "Mina dagböcker",
      "Owner": "Ägare",
      "Admin": "Administratör",
      "Reader": "Läsare",
      "CreateDiary": "Skapa egen dagbok",
      "NoDiary": "Du har ingen dagbok. Skapa en gratis!"
    }
    }
</i18n>

<script>
import { mapState } from "vuex";
import BlogAvatar from "@/components/avatars/BlogAvatar.vue";

export default {
  data: () => ({}),
  computed: {
    ...mapState({
      user: (state) => state.account.user,
    }),
    show_create: function () {
      if (this.user && this.user.diaries && this.user.diaries.length > 0) {
        for (var i = 0; i < this.user.diaries.length; i++) {
          if (this.user.diaries[i].roles.indexOf(1) > -1) return false;
        }
      }
      return true;
    },
  },
  components: {
    "blog-avatar": BlogAvatar,
  },
  created() {},
  methods: {
    getRole(roles) {
      if (!roles) return "";

      if (roles.indexOf(this.$enums.DIARY_ROLE.Owner) >= 0)
        return this.$t("Owner");
      if (roles.indexOf(this.$enums.DIARY_ROLE.Admin) >= 0)
        return this.$t("Admin");
      if (roles.indexOf(this.$enums.DIARY_ROLE.Reader) >= 0)
        return this.$t("Reader");
      return "";
    },
  },
};
</script>

<style scoped>
.sub-header {
  height: auto !important;
  padding-bottom: 4px;
}
.sub-header a:hover {
  text-decoration: underline !important;
}

.show-all a {
  text-decoration: none;
  font-size: 14px;
}
.show-all a:hover {
  text-decoration: underline;
}

.message-container {
  max-height: 400px;
  overflow-y: auto;
}
</style>