<template>
  <div>
    <v-app-bar dark app color="#457b9d">
      <v-btn to="/" class="text-none logo-font logo-top" text>
        <v-icon left class="mr-4" size="28"
          >$vuetify.icons.bookOpenPageVariantOutline</v-icon
        >
        {{ $t("PageName") }}
      </v-btn>

      <v-spacer></v-spacer>
      <v-app-bar-nav-icon @click.stop="menu = !menu"></v-app-bar-nav-icon>

      <template v-slot:extension>
        <template v-if="user">
          <v-row no-gutters>
            <v-col xs="2" class="text-center">
              <v-btn
                color="#2C6284"
                depressed
                to="/"
                fab
                small
                class="mr-4 lighten-1"
              >
                <v-icon>$vuetify.icons.fasHome</v-icon>
              </v-btn>
            </v-col>
            <v-col xs="2" class="text-center" v-if="show_diaries">
              <diaries-menu></diaries-menu>
            </v-col>
            <v-col xs="2" class="text-center"
              ><message-menu></message-menu
            ></v-col>
            <v-col xs="2" class="text-center"
              ><notification-menu></notification-menu
            ></v-col>
            <v-col xs="2" class="text-center"
              ><account-menu></account-menu
            ></v-col>
          </v-row>
        </template>

        <v-tabs
          fixed-tabs
          centered
          hide-slider
          v-else
          class="mobile-tabs-fix mobile-bar-tabs"
        >
          <v-tab to="/" class="text-none">{{ $t("Home") }}</v-tab>
          <v-tab to="/signup" class="text-none">{{ $t("SignUp") }}</v-tab>
          <v-tab to="/login" class="text-none">{{ $t("Login") }}</v-tab>
        </v-tabs>
      </template>
    </v-app-bar>

    <v-navigation-drawer v-model="menu" app temporary>
      <v-list>
        <template v-if="!user">
          <v-list-item to="/">
            <v-list-item-icon>
              <v-icon>$vuetify.icons.fasHome</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ $t("Home") }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item to="/signup">
            <v-list-item-icon>
              <v-icon>$vuetify.icons.fasUserPlus</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ $t("SignUp") }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item to="/login">
            <v-list-item-icon>
              <v-icon>$vuetify.icons.fasSignInAlt</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ $t("Login") }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>

        <template v-if="user">
          <template v-if="user.current_diary">
            <v-list-item :to="getDiaryUrl(null)">
              <v-list-item-icon>
                <v-icon>$vuetify.icons.fasHome</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>{{ $t("Home") }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
          <template v-if="!user.current_diary">
            <v-list-item to="/">
              <v-list-item-icon>
                <v-icon>$vuetify.icons.fasHome</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>{{ $t("Home") }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </template>

        <v-list-item to="/offers" v-if="show_offers">
          <v-list-item-icon>
            <v-icon>$vuetify.icons.fasShoppingCart</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ $t("Offers") }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item to="/news">
          <v-list-item-icon>
            <v-icon>$vuetify.icons.fasNewspaper</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ $t("News") }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item to="/terms">
          <v-list-item-icon>
            <v-icon>$vuetify.icons.fasAsterisk</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ $t("Terms") }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item to="/privacy">
          <v-list-item-icon>
            <v-icon>$vuetify.icons.fasUserSecret</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ $t("Privacy") }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item to="/faq">
          <v-list-item-icon>
            <v-icon>$vuetify.icons.fasQuestionCircle</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ $t("Faq") }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item to="/contact">
          <v-list-item-icon>
            <v-icon>$vuetify.icons.fasEnvelopeOpen</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ $t("Contact") }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<i18n>
    {
    "en": {
    "SignUp": "Sign up",
    "Login": "Login",
    "PageName": "Babiry",
    "Home": "Home",
    "LoggedIn": "Logged In",
    "Notifications": "Notifications",
    "Messages": "Messages",
    "Post": "Diary post",
    "Album": "Album",
    "Child": "Child",
    "Logout": "Logout",
    "Account": "My account",
    "News": "News",
    "About": "About",
    "Terms": "Terms",
    "Privacy": "Privacy",
    "Faq": "Faq",
    "Contact": "Contact",
    "Offers": "Offers",
    "ShowOffers": "false",
    "Page": "Page",
    "PostDesc": "Share what happened today.",
    "ChildDesc": "Register a child in your diary",
    "AlbumDesc": "Create an album and upload photos",
    "PageDesc": "Create your own customizable page"
    },
    "sv": {
    "SignUp": "Bli medlem",
    "Login": "Logga in",
    "PageName": "Barndagboken",
    "Home": "Startsida",
    "LoggedIn": "Inloggad",
    "Notifications": "Aviseringar",
    "Messages": "Meddelande",
    "Post": "Inlägg",
    "Album": "Album",
    "Child": "Barn",
    "Logout": "Logga ut",
    "Account": "Mitt konto",
    "Offers": "Erbjudanden",
    "News": "Nyheter",
    "About": "Om",
    "Terms": "Användarvilkor",
    "Privacy": "Sekretesspolicy",
    "Faq": "Faq",
    "Contact": "Kontakt",
    "Page": "Sida",
    "PostDesc": "Dela med dig av vad som har hänt idag.",
    "ChildDesc": "Registrera ett barn i din dagbok",
    "AlbumDesc": "Skapa ett album och ladda upp bilder",
    "PageDesc": "Skapa en egen anpassningsbar sida"
    }
    }
</i18n>

<script>
import { mapState } from "vuex";
import NotificationMenu from "../topmenu/NotificationMenu.vue";
import MessageMenu from "../topmenu/MessageMenu.vue";
import AccountMenu from "../topmenu/AccountMenu.vue";
import MyDiariesMenu from "../topmenu/MyDiariesMenu.vue";

export default {
  name: "MainMobileBar",
  data: () => ({
    menu: false,
    mess_menu: false,
    notification_menu: false,
    show_notifications_badget: false,
    show_message_badget: false,
    formated_unit_unread_notifications: 0,
    formated_unit_unread_pm: 0,
    show_offers: process.env.VUE_APP_SHOW_OFFERS === "true",
  }),
  components: {
    "notification-menu": NotificationMenu,
    "message-menu": MessageMenu,
    "account-menu": AccountMenu,
    "diaries-menu": MyDiariesMenu,
  },
  computed: {
    ...mapState({
      user: (state) => state.account.user,
    }),
    show_diaries: function () {
      if(!this.user) return false;
      
      if (this.user.diaries == null || this.user.diaries.length == 0)
        return true;

      if (this.user.diaries.length > 1) return true;

      if (this.user.diaries.length == 1 && this.user.diaries[0].roles.length > 0) {
        if (this.user.diaries[0].roles.indexOf(1) == -1) return true;
      }

      return false;
    },
  },
  created() {
    if (this.user) {
      this.formatUnreadNotifications(this.user.unit_unread_notifications);
      this.formatUnreadPm(this.user.unit_unread_pm);
    }
  },
  methods: {
    getDiaryUrl(path) {
      var url = "/" + this.user.current_diary.name;

      if (path) url += "/" + path;

      return url;
    },
    formatUnreadNotifications(unit) {
      this.show_notifications_badget = true;

      if (!unit || unit < 1) {
        this.formated_unit_unread_notifications = 0;
        this.show_notifications_badget = false;
      } else if (unit > 99) {
        this.formated_unit_unread_notifications = "99+";
      } else {
        this.formated_unit_unread_notifications = unit;
      }
    },
    formatUnreadPm(unit) {
      this.show_message_badget = true;

      if (!unit || unit < 1) {
        this.show_message_badget = false;
        this.formated_unit_unread_pm = 0;
      } else if (unit > 99) {
        this.formated_unit_unread_pm = "99+";
      } else {
        this.formated_unit_unread_pm = unit;
      }
    },
    newChild() {
      this.$refs.childDialog.open(this.user.current_diary.name);
    },
    newAlbum() {
      this.$refs.albumDialog.open(this.user.current_diary.name);
    },
  },
  watch: {
    "user.unit_unread_notifications": function (newVal) {
      this.formatUnreadNotifications(newVal);
    },
    "user.unit_unread_pm": function (newVal) {
      this.formatUnreadPm(newVal);
    },
  },
};
</script>

<style scoped>
.logo-font.v-btn--active::before {
  opacity: 1;
  background-color: transparent !important;
}
</style>