<template>
  <span v-if="error" class="error-text pr-2 pt-2 pl-2 pb-2">{{
    getText(error)
  }}</span>
</template>

<script>
import { responseHelpers } from "@/_helpers";

export default {
  name: "InlineError",
  props: ["error"],
  methods: {
    getText(error) {
      return responseHelpers.errorTextFromResponse(error);
    },
  },
};
</script>