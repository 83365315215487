<template>
  <v-skeleton-loader :loading="is_loading" type="list-item-avatar-two-line">
    <v-list :two-line="!items || items.length == 0" class="notification-menu">
      <v-subheader class="sub-header">
        {{ $t("Notifications") }}
        <v-spacer></v-spacer>
        <span
          @click="setAllReaded"
          :disabled="is_all_updating"
          v-bind:class="{ is_all_updating: is_all_updating }"
          >{{ $t("SetAllReaded") }}</span
        >
      </v-subheader>
      <v-divider></v-divider>

      <template v-if="!error && items && items.length > 0">
        <div class="notification-container notification-list">
          <template v-for="(item, index) in notifications">
            <v-list-item
              :key="item.id"
              v-bind:class="{ unreaded: !item.is_read }"
            >
              <v-list-item-avatar @click="goTo(item)" class="mr-2 pointer">
                <v-icon class="grey lighten-2">{{ item.icon }}</v-icon>
              </v-list-item-avatar>
              <v-list-item-content @click="goTo(item)" class="pointer">
                <div v-html="item.text" class="notification-text"></div>
                <v-list-item-subtitle class="global-list-desc">{{
                  item.time_ago
                }}</v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-action>
                <v-menu
                  close-on-click
                  close-on-content-click
                  :disabled="item.is_deleting"
                  offset-y
                >
                  <template v-slot:activator="{ on }">
                    <v-btn
                      icon
                      :loading="item.is_deleting"
                      :disabled="item.is_deleting"
                      v-on="on"
                      class="mr-n3"
                    >
                      <v-icon>$vuetify.icons.dotsVertical</v-icon>
                    </v-btn>
                  </template>
                  <v-list class="admin-list-menu">
                    <v-list-item
                      @click="setReaded(item)"
                      v-if="!item.is_read"
                      exact
                      :disabled="item.is_updating || is_all_updating"
                    >
                      <v-list-item-icon>
                        <v-icon>$vuetify.icons.messageAlertOutline</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>{{
                          $t("SetAsReaded")
                        }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item
                      @click="setUnReaded(item)"
                      v-if="item.is_read"
                      exact
                      :disabled="item.is_updating || is_all_updating"
                    >
                      <v-list-item-icon>
                        <v-icon>$vuetify.icons.messageAlert</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>{{
                          $t("SetAsUnreaded")
                        }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item @click="deleteAction(item)">
                      <v-list-item-icon>
                        <v-icon>$vuetify.icons.trashCanOutline</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>{{
                          $t("Delete")
                        }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-list-item-action>
            </v-list-item>
            <v-divider :key="index" v-if="index < items.length"></v-divider>
          </template>
        </div>
        <v-divider></v-divider>
        <div class="text-center pt-2 show-all">
          <router-link :to="{ name: 'account_notifications' }">{{
            $t("ShowAll")
          }}</router-link>
        </div>
      </template>

      <div
        v-if="!error && (!items || items.length == 0)"
        class="text-center pt-5 pb-5"
      >
        {{ $t("NoNotifications") }}
      </div>
      <div v-if="error" class="text-center error-text pt-2 pb-2">
        {{ error }}
      </div>
    </v-list>
  </v-skeleton-loader>
</template>

<i18n>
    {
    "en": {
    "Notifications": "Notifications",
    "NoNotifications": "Nothing to show!",
    "ShowAll": "Show all",
    "Delete": "Delete this notification",
    "SuccessDelete": "Notification deleted!",
    "SetAsReaded": "Mark as readed",
    "SetAsUnreaded": "Mark as unreaded",
    "SetAllReaded": "Mark all as readed"
    },
    "sv": {
    "Notifications": "Aviseringar",
    "NoNotifications": "Finns inga aviseringar att visa!",
    "ShowAll": "Visa alla",
    "Delete": "Ta bort aviseringen",
    "SuccessDelete": "Aviseringen togs bort!",
    "SetAsReaded": "Markera som läst",
    "SetAsUnreaded": "Markera som oläst",
    "SetAllReaded": "Markera alla som lästa"
    }
    }
</i18n>

<script>
import { mapState, mapActions } from "vuex";
import { notificationCreator } from "../../_helpers";

export default {
  data: () => ({}),
  computed: {
    ...mapState({
      user: (state) => state.account.user,
      is_loading: (state) => state.account.notifications.is_loading,
      current_page: (state) => state.account.notifications.current_page,
      has_more: (state) => state.account.notifications.has_more,
      has_new_data: (state) => state.account.notifications.has_new_data,
      error: (state) => state.account.notifications.error,
      items: (state) => state.account.notifications.list,
      is_all_updating: (state) => state.account.notifications.is_all_updating,
    }),
    notifications: function () {
      return notificationCreator.formatedNotifications(this.items);
    },
  },
  created() {
    this.load();
  },
  methods: {
    ...mapActions({
      inbox: "account/notifications/inbox",
      deleteNotification: "account/notifications/delete",
      dbSetUnReaded: "account/notifications/setUnReaded",
      dbSetReaded: "account/notifications/setReaded",
      dbSetAllReaded: "account/notifications/setAllReaded",
    }),
    load() {
      var self = this;

      if (this.has_new_data) {
        this.$store.dispatch("account/notifications/reset", null, {
          root: true,
        });
      }

      if (self.is_loading || self.current_page > 0) return;

      this.inbox();
    },
    loadMore() {
      this.inbox();
    },
    goTo(item) {
      this.$router.push(item.url);

      if (!item.is_read) this.setReaded(item);
    },
    setReaded(item) {
      var self = this;

      self
        .dbSetReaded(item.id)
        .then(function () {
          item.is_read = true;
        })
        .catch(function (error) {
          self.$ajaxErrorNoty(error);
        });
    },
    setAllReaded() {
      var self = this;
      if (self.is_all_updating) return;

      self
        .dbSetAllReaded()
        .then(function () {})
        .catch(function (error) {
          self.$ajaxErrorNoty(error);
        });
    },
    setUnReaded(item) {
      var self = this;

      self
        .dbSetUnReaded(item.id)
        .then(function () {})
        .catch(function (error) {
          self.$ajaxErrorNoty(error);
        });
    },
    deleteAction(item) {
      var self = this;

      self
        .deleteNotification(item.id)
        .then(function () {
          self.$successNoty(self.$t("SuccessDelete"));
        })
        .catch(function (error) {
          self.$ajaxErrorNoty(error);
        });
    },
  },
};
</script>


<style>
.notification-menu .sub-header {
  height: auto !important;
  padding-bottom: 4px;
}
.notification-menu .show-all a {
  text-decoration: none;
  font-size: 14px;
}
.notification-menu .show-all a:hover {
  text-decoration: underline;
}

.notification-menu .sub-header span {
  font-weight: normal;
  cursor: pointer;
  color: #1976d2;
}
.notification-menu .sub-header span:hover {
  text-decoration: underline !important;
}
.notification-menu .sub-header span.is_all_updating {
  color: gray !important;
  text-decoration: none !important;
  cursor: wait;
}

.notification-menu .notification-container {
  max-height: 400px;
  overflow-y: auto;
}

.notification-menu .notification-text {
  font-size: 15px !important;
}

.notification-menu .notification-text span.user,
.notification-menu .notification-text span.blog,
.notification-menu .notification-text span.post,
.notification-menu .notification-text span.media {
  font-weight:500 !important;
}

</style>