<template>
  <v-dialog
    v-model="inputVal"
    :persistent="persistent"
    :fullscreen="$isMobile && !preventMobile"
    :max-width="maxWidth"
    :width="width"
  >
    <v-card tile :class="{ mobile_container: $isMobile }">
      <v-toolbar color="#457b9d" dark flat class="dialog-bar">
        <v-btn icon dark v-if="$isMobile" @click="close">
          <v-icon>$vuetify.icons.close</v-icon>
        </v-btn>
        <v-toolbar-title class="pl-1">{{ title }}</v-toolbar-title>
        <v-spacer></v-spacer>

        <v-btn
          icon
          dark
          v-if="!$isMobile"
          @click="close"
          class="mr-0"
          :title="$t('Button.Close')"
        >
          <v-icon>$vuetify.icons.close</v-icon>
        </v-btn>
        <v-btn
          dark
          text
          v-if="$isMobile && !hideSubmit"
          class="mr-0"
          :disabled="submitDisabled"
          :loading="isSaving"
          @click="submit"
        >
          {{ getSubmitText(submitText) }}
        </v-btn>
      </v-toolbar>

      <slot name="top"></slot>
      <v-card-text :style="contentStyle" :class="contentClass" class="pt-5">
        <slot name="content"></slot>
      </v-card-text>
      <v-card-actions>
        <slot name="footer"></slot>

        <template v-if="!$isMobile && !hideSubmit">
          <v-spacer></v-spacer>

          <v-btn
            color="blue darken-1"
            text
            v-if="showCancel"
            @click="close"
            :disabled="isSaving"
            class="text-none"
            >{{ $t("Button.Cancel") }}</v-btn
          >
          <v-btn
            color="primary"
            @click="submit"
            :loading="isSaving"
            :disabled="submitDisabled"
            class="text-none"
            >{{ getSubmitText(submitText) }}</v-btn
          >
        </template>
      </v-card-actions>
      <slot name="bottom"></slot>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "form_dialog",

  props: {
    title: {
      type: String,
      default: "",
    },
    value: {},
    isSaving: {
      type: Boolean,
      default: false,
    },
    persistent: {
      type: Boolean,
      default: true,
    },
    maxWidth: {
      type: String,
      default: "400px",
    },
    width: {
      type: String,
      default: "",
    },
    hideSubmit: {
      type: Boolean,
      default: false,
    },
    submitText: {
      type: String,
      default: null,
    },
    submitDisabled: {
      type: Boolean,
      default: false,
    },
    showCancel: {
      type: Boolean,
      default: false,
    },
    preventMobile: {
      type: Boolean,
      default: false,
    },
    contentStyle: {
      type: String,
      default: "",
    },
    contentClass: {
      type: String,
      default: "",
    },
  },
  computed: {
    inputVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  data: () => ({}),
  created: function () {},
  methods: {
    submit() {
      this.$emit("submit");
    },
    close() {
      this.inputVal = false;
      this.$emit("close");
    },
    getSubmitText(text) {
      if (!text || text == "") return this.$t("Button.Save");

      return text;
    },
  },
};
</script>

<style scoped>
.max-400 {
  max-height: 400px;
  overflow: auto;
}

.dialog-bar {
  position: sticky;
  width: 100%;
  top: 0 !important;
  z-index: 10000;
}

.mobile_container .max-400 {
  max-height: initial !important;
  overflow: auto;
}
</style>