<template>
  <v-btn
    color="#5b7bd5"
    dark
    class="text-none fb-button"
    v-on:click="socialConnect('facebook')"
    :disabled="status.is_authorizing && !status.fb_authorizing"
    :loading="status.fb_authorizing"
  >
    <v-icon dark left>$vuetify.icons.fabFacebookF</v-icon>
    {{ $t("ConnectFacebook") }}
  </v-btn>
</template>

<i18n>
    {
    "en": {
    "ConnectFacebook": "Connect with Facebook"
    },
    "sv": {
    "ConnectFacebook": "Anslut med Facebook"
    }
    }
</i18n>

<script>
import { mapState, mapActions } from "vuex";
const HelloJs = require("hellojs/dist/hello.all.min.js");
import { responseHelpers } from "@/_helpers";

export default {
  computed: {
    ...mapState("account", ["status"]),
  },
  created() {
    HelloJs.init(
      {
        facebook: process.env.VUE_APP_FACEBOOK_APP_ID,
      },
      {
        redirect_uri: "/redirect.html",
      }
    );
  },
  methods: {
    ...mapActions("account", ["oauthConnect"]),

    socialConnect(network) {
      var self = this;
      self.$ga.event("User", "Social-Connect-Open", network, 1);

      HelloJs(network)
        .login({
          scope: "email",
        })
        .then(() => {
          const authRes = HelloJs(network).getAuthResponse();
          self.$ga.event("User", "social_connect_accepted", network, 1);

          self
            .oauthConnect({ type: network, token: authRes.access_token })
            .then(function (response) {
              self.$emit("success", response.data);
            })
            .catch(function (error) {
              self.$ga.exception(responseHelpers.errorTextFromResponse(error));
              self.$emit("error", error);
            });
        });
    },
  },
};
</script>

<style scoped>
.theme--dark.v-btn.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
  background-color: #5b7bd5 !important;
}
</style>