<template>
  <v-footer color="#1d3557">
    <v-container class="text-center">
      <div v-if="!$isMobile" class="pb-3">
        <v-btn color="white" text rounded to="/" class="my-2 text-none">
          {{ $t("Home") }}
        </v-btn>
        <v-btn
          color="white"
          text
          v-if="show_offers"
          rounded
          to="/offers"
          class="my-2 text-none"
        >
          {{ $t("Offers") }}
        </v-btn>
        <v-btn color="white" text rounded to="/news" class="my-2 text-none">
          {{ $t("News") }}
        </v-btn>
        <v-btn color="white" text rounded to="/terms" class="my-2 text-none">
          {{ $t("Terms") }}
        </v-btn>
        <v-btn color="white" text rounded to="/privacy" class="my-2 text-none">
          {{ $t("Privacy") }}
        </v-btn>
        <v-btn color="white" text rounded to="/faq" class="my-2 text-none">
          {{ $t("Faq") }}
        </v-btn>
        <v-btn color="white" text rounded to="/contact" class="my-2 text-none">
          {{ $t("Contact") }}
        </v-btn>
      </div>

      <div>
        <v-btn
          v-for="icon in social_icons"
          :key="icon.icon"
          class="mx-4 white--text"
          :href="icon.url"
          target="_blank"
          rel="noreferrer"
          :title="icon.title"
          icon
        >
          <v-icon size="24px">{{ icon.icon }}</v-icon>
        </v-btn>
      </div>

      <v-card-text
        class="white--text pt-7 pb-6"
        v-html="$t('FooterText')"
      ></v-card-text>

      <v-card-text class="white--text">
        {{ new Date().getFullYear() }} — <strong>{{ $t("PageName") }}</strong>
      </v-card-text>
    </v-container>
  </v-footer>
</template>

<i18n>
    {
    "en": {
    "Home": "Home",
    "Offers": "Offers",
    "News": "News",
    "About": "About",
    "Terms": "Terms",
    "Privacy": "Privacy",
    "Faq": "Faq",
    "Contact": "Contact",
    "PageName": "Babiry",
    "VisitFacebook": "Visit us on Facebook",
    "VisitTwitter": "Visit us on Twitter",
    "VisitInstagram": "Visit us on Instagram",
    "FooterText": "Babiry.com - From birth through to the present day. On Babiry you can collect all the photos and memories of your children so you can remember them forever. The first steps, the first words, favorite food and much more. Share memories with family members and friends."
    },
    "sv": {
    "Home": "Hem",
    "Offers": "Erbjudanden",
    "News": "Nyheter",
    "About": "Om",
    "Terms": "Användarvilkor",
    "Privacy": "Sekretesspolicy",
    "Faq": "Faq",
    "Contact": "Kontakt",
    "PageName": "Barndagboken",
    "VisitFacebook": "Besök oss på Facebook",
    "VisitTwitter": "Besök oss på Twitter",
    "VisitInstagram": "Besök oss på Instagram",
    "FooterText": "Barndagboken.se - Från födseln till idag. På Barndagboken kan du samla alla foton och minnen från dina barn så att du kommer ihåg dem för alltid. De första stegen, de första orden, favoritmat och mycket mer. Dela minnen med familjemedlemmar och vänner."
    }
    }
</i18n>

<script>
export default {
  name: "MainFooter",

  data: () => ({
    social_icons: [],
    show_offers: process.env.VUE_APP_SHOW_OFFERS === "true",
  }),
  created: function () {
    var self = this;
    this.social_icons = [
      {
        icon: "$vuetify.icons.fabFacebook",
        url: process.env.VUE_APP_FACEBOOK_PAGE_URL,
        title: self.$t("VisitFacebook"),
      },
      {
        icon: "$vuetify.icons.fabTwitter",
        url: process.env.VUE_APP_TWITTER_PAGE_URL,
        title: self.$t("VisitTwitter"),
      },
      {
        icon: "$vuetify.icons.fabInstagram",
        url: process.env.VUE_APP_INSTAGRAM_PAGE_URL,
        title: self.$t("VisitInstagram"),
      },
    ];
  },
};
</script>