// Recomendation of babeljs (https://babeljs.io/docs/en/babel-polyfill)
import "regenerator-runtime/runtime"; // To ensure that regeneratorRuntime is defined globally
import "./registerServiceWorker";
import Vue from "vue";
import "./plugins/axios";
import App from "./App.vue";
import router from "./router";
import { store } from "./store";
import vuetify from "./plugins/vuetify";
import i18n from "./plugins/i18n";
import VueMeta from "vue-meta";
import VueAnalytics from "vue-analytics";
import { responseHelpers, imageHelpers, enums } from "./_helpers";
import VuetifyConfirm from "vuetify-confirm";

/* SETTINGS */
Vue.config.productionTip = false;
/* -- SETTINGS -- */

Vue.use(VueMeta, {
  refreshOnceOnNavigation: true,
});

Vue.use(VuetifyConfirm, {
  vuetify,
  buttonTrueText: i18n.t("Button.Yes"),
  buttonFalseText: i18n.t("Button.No"),
  color: "#457b9d",
  icon: "",
  title: i18n.t("Confirm.Title"),
});

Vue.use(VueAnalytics, {
  id: "UA-21227119-1",
  router,
  linkers: ["babiry.com", "barndagboken.se"],
  debug: {
    sendHitTask: process.env.VUE_APP_IS_PROD === "true",
  },
  autoTracking: {
    exception: true,
    exceptionLogs: process.env.VUE_APP_IS_PROD !== "true",
  },
});

/* GLOBALA FILTER */
Vue.filter("capitalize", function(value) {
  if (!value) return "";
  value = value.toString();
  return value.charAt(0).toUpperCase() + value.slice(1);
});

/* --GLOBALA FILTER-- */

/* GLOBAL COMPONENTS*/
import ErrorInline from "./components/errors/Inline.vue";
import ErrorBox from "./components/errors/Box.vue";
import ErrorPage from "./components/errors/Page.vue";
import FormDialog from "@/components/helpers/FormDialog.vue";
import ButtonMenu from "@/components/helpers/ButtonMenu.vue";

Vue.component("error-inline", ErrorInline);
Vue.component("error-box", ErrorBox);
Vue.component("error-page", ErrorPage);
Vue.component("form-dialog", FormDialog);
Vue.component("button-menu", ButtonMenu);

Vue.component("render-string", {
  props: {
    string: {
      required: true,
      type: String,
    },
  },
  render(h) {
    const render = {
      template: "<div>" + this.string + "</div>",
      methods: {
        markComplete() {},
      },
    };
    return h(render);
  },
});

/* --GLOBAL VUE VARIABLES--*/
Object.defineProperty(Vue.prototype, "$imagehelpers", { value: imageHelpers });
Object.defineProperty(Vue.prototype, "$enums", { value: enums });
Object.defineProperty(Vue.prototype, "$isMobile", {
  value: window.innerWidth < 768,
});
Object.defineProperty(Vue.prototype, "$bannerHeight", {
  value: window.innerWidth > 699 ? 300 : 175,
});
Object.defineProperty(Vue.prototype, "$nudgeTop", {
  value: window.innerWidth > 699 ? 8 : 4,
});

/* --GLOBAL COMPONENTS--*/

String.prototype.trim = function() {
  return this.replace(/^\s+|\s+$/g, "");
};

String.prototype.trimEllip = function(length) {
  return this.length > length ? this.substring(0, length) + "..." : this;
};

// Auto-link Twitter hashtags in a string
// Usage: mystring.parseHashtag()
String.prototype.parseHashtag = function() {
  return this.replace(/[#]+[A-Za-z0-9-_]+/g, function(t) {
    var tag = t.replace("#", "%23");

    return t.link("/search?q=" + tag);
  });
};

// Auto-link Twitter usernames in a string
// Usage: mystring.parseUsername()
String.prototype.parseUsername = function() {
  return this.replace(/[@]+[A-Za-z0-9-_]+/g, function(u) {
    var username = u.replace("@", "");

    return u.link("/user/" + username);
  });
};

// Auto-link URLs in a string
// Usage: mystring.parseURL()
String.prototype.parseURL = function() {
  return this.replace(
    /[A-Za-z]+:\/\/[A-Za-z0-9-_]+\.[A-Za-z0-9-_:%&~?/.=]+/g,
    function(url) {
      return url.link(url);
    }
  );
};

String.prototype.escapeHtml = function() {
  var tagsToReplace = {
    "&": "&amp;",
    "<": "&lt;",
    ">": "&gt;",
  };
  return this.replace(/[&<>]/g, function(tag) {
    return tagsToReplace[tag] || tag;
  });
};

Vue.mixin({
  methods: {
    $successNoty: function(header, message) {
      var msg = "";

      if (header) msg = "<span class='subtitle-2'>" + header + "</span>";

      if (message) msg += "<span class='body-2'>" + message + "</span>";

      this.$toast.success(msg, { icon: "$vuetify.icons.farCheckCircle" });
    },
    $errorNoty: function(header, message) {
      var msg = "";

      if (header) msg = "<div class='subtitle-2'>" + header + "</div>";

      if (message) msg += "<div class='body-2'>" + message + "</div>";

      this.$toast.error(msg, { icon: "$vuetify.icons.farFrown" });
    },
    $ajaxErrorNoty: function(request) {
      const msg = responseHelpers.errorTextFromResponse(request);
      this.$errorNoty(i18n.t("Error.ErrorOccured"), msg);
    },
    $objectToArray: function(obj) {
      return (
        Object.keys(obj)
          // iterate over them and generate the array
          .map(function(k) {
            // generate the array element
            return { key: k, value: obj[k] };
          })
      );
    },
  },
});

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
