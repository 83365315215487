<template>
  <div>
    <v-btn
      color="#2C6284"
      depressed
      fab
      small
      class="mr-4"
      v-if="$isMobile"
      to="/account/notifications"
    >
      <v-badge
        color="red"
        overlap
        :content="formated_unit_unread_notifications"
        v-model="show_notifications_badget"
      >
        <v-icon>$vuetify.icons.fasBell</v-icon>
      </v-badge>
    </v-btn>

    <v-menu
      close-on-click
      close-on-content-click
      v-model="is_open"
      :nudge-bottom="$nudgeTop"
      offset-y
      v-if="!$isMobile"
    >
      <template v-slot:activator="{ on: onMenu, attrs }">
        <v-tooltip bottom :disabled="$isMobile">
          <template v-slot:activator="{ on: onTooltip }">
            <v-btn
              color="#2C6284"
              depressed
              v-on="{ ...onMenu, ...onTooltip }"
              v-bind="attrs"
              fab
              small
              class="mr-4"
            >
              <v-badge
                color="red"
                overlap
                :content="formated_unit_unread_notifications"
                v-model="show_notifications_badget"
              >
                <v-icon>$vuetify.icons.fasBell</v-icon>
              </v-badge>
            </v-btn>
          </template>
          <span>{{ $t("Notifications") }}</span>
        </v-tooltip>
      </template>
      <v-card width="400" class="top-bar-dropdown">
        <noty-menu ref="notyList"></noty-menu>
      </v-card>
    </v-menu>
  </div>
</template>

<i18n>
    {
    "en": {
    "NewNotification": "New notification!",
    "Notifications": "Notifications"
    },
    "sv": {
    "NewNotification": "Ny notifiering!",
    "Notifications": "Aviseringar"
    }
    }
</i18n>


<script>
import { mapState } from "vuex";
import NotificationMenuList from "./NotificationMenuList.vue";

export default {
  data: () => ({
    formated_unit_unread_notifications: 0,
    show_notifications_badget: false,
    is_open: false,
  }),
  components: {
    "noty-menu": NotificationMenuList,
  },
  computed: {
    ...mapState({
      user: (state) => state.account.user,
    }),
  },
  created() {
    if (this.user) {
      this.formatUnreadNotifications(this.user.unit_unread_notifications);
    }
  },
  methods: {
    formatUnreadNotifications(unit) {
      this.show_notifications_badget = true;

      if (!unit || unit < 1) {
        this.formated_unit_unread_notifications = 0;
        this.show_notifications_badget = false;
      } else if (unit > 99) {
        this.formated_unit_unread_notifications = "99+";
      } else {
        this.formated_unit_unread_notifications = unit;
      }
    },
  },
  watch: {
    is_open: function (newVal) {
      if (newVal && this.$refs.notyList) {
        this.$refs.notyList.load();
      }
    },
    "user.unit_unread_notifications": function (newVal, oldVal) {
      this.formatUnreadNotifications(newVal);

      if (newVal > oldVal) {
        this.$toast.info(this.$t("NewNotification"), { icon: "$vuetify.icons.fasBell" });

        this.$store.dispatch("account/notifications/hasNewData", null, {
          root: true,
        });
      }
    },
  },
};
</script>