<template>
  <div>
    <v-btn
      color="#2C6284"
      depressed
      fab
      small
      class="mr-4"
      v-if="$isMobile"
      @click="mobile_menu = true"
    >
      <v-icon>$vuetify.icons.fasStar</v-icon>
    </v-btn>

    <v-bottom-sheet v-model="mobile_menu">
      <diaries-menu v-on:click="mobile_menu = false"></diaries-menu>
    </v-bottom-sheet>

    <v-menu
      close-on-click
      close-on-content-click
      v-model="is_open"
      :nudge-bottom="$nudgeTop"
      offset-y
      v-if="!$isMobile"
    >
      <template v-slot:activator="{ on: onMenu, attrs }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on: onTooltip }">
            <v-btn
              color="#2C6284"
              depressed
              v-on="{ ...onMenu, ...onTooltip }"
              v-bind="attrs"
              fab
              small
              class="mr-4"
            >
              <v-icon>$vuetify.icons.fasStar</v-icon>
            </v-btn>
          </template>
          <span>{{ $t("MyDiaries") }}</span>
        </v-tooltip>
      </template>
      <v-card width="400" class="top-bar-dropdown">
        <diaries-menu></diaries-menu>
      </v-card>
    </v-menu>
  </div>
</template>

<i18n>
    {
    "en": {
    "MyDiaries": "My diaries"
    },
    "sv": {
    "MyDiaries": "Mina dagböcker"
    }
    }
</i18n>

<script>
import { mapState } from "vuex";
import MyDiariesMenuList from "./MyDiariesMenuList.vue";

export default {
  data: () => ({
    is_open: false,
    mobile_menu: false,
  }),
  components: {
    "diaries-menu": MyDiariesMenuList,
  },
  computed: {
    ...mapState({
      user: (state) => state.account.user,
    }),
  },
  created() {},
  methods: {},
  watch: {
    $route() {
      this.is_open = false;
      this.mobile_menu = false;
    },
  },
};
</script>