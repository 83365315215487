<template>
  <v-card
    :flat="!border"
    class="text-center pt-10 pb-10"
    v-if="error"
    style="
      background-color: transparent !important;
      max-width: 500px;
      margin: auto;
    "
  >
    <v-icon class="mb-5 icon" size="100px">$vuetify.icons.emoticonSadOutline</v-icon>
    <br />
    <h2>
      {{ getText(error) }}
    </h2>
    <div class="mt-5" v-if="home">
      <v-btn color="primary" class="text-none" :to="home">{{
        $t("Home")
      }}</v-btn>
    </div>
  </v-card>
</template>

<i18n>
    {
    "en": {
    "AnErrorOccured": "An error occured",
    "PageNotFound": "Page not found",
    "Home": "Go to Home"
    },
    "sv": {
    "AnErrorOccured": "Ett fel inträffade",
    "PageNotFound": "Sidan kunde inte hittas",
    "Home": "Till Startsidan"
    }
    }
</i18n>

<script>
import { responseHelpers } from "@/_helpers";

export default {
  name: "PageError",
  props: ["error", "border", "home"],
  metaInfo() {
    return this.meta;
  },
  data: () => ({
    meta: {},
    statuscode: 500,
  }),
  methods: {
    getText(error) {
      this.generateMeta();
      return responseHelpers.errorTextFromResponse(error);
    },
    generateMeta() {
      var self = this;
      var responseCode = responseHelpers.getErrorResponseCode(self.error);

      if (responseCode) self.statuscode = responseCode;

      var title = self.$t("AnErrorOccured");

      if (self.statuscode == 404) title = self.$t("PageNotFound");

      self.meta = {
        title: title,
        meta: [{ vmid: "status", name: "status", content: self.statuscode }],
      };
    },
  },
};
</script>