<template>
  <v-skeleton-loader :loading="is_loading" type="list-item-avatar-two-line">
    <v-list two-line>
      <v-subheader class="sub-header">
        {{ $t("Last") }}
        <v-spacer></v-spacer>
        <router-link
          style="text-decoration: none"
          :to="{ name: 'account_messages_new' }"
          >{{ $t("NewMessage") }}</router-link
        >
      </v-subheader>
      <v-divider></v-divider>

      <template v-if="!error && messages && messages.length > 0">
        <div class="message-container notification-list">
          <template v-for="(item, index) in messages">
            <v-list-item
              :key="item.id"
              :to="{ name: 'account_messages_read', params: { id: item.id } }"
              v-bind:class="{ unreaded: !item.is_read }"
            >
              <v-list-item-avatar>
                <user-avatar :user="item.sender" :size="40"></user-avatar>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title class="global-list-header">{{
                  item.sender.username | capitalize
                }}</v-list-item-title>
                <v-list-item-subtitle class="global-list-desc">{{
                  item.subject | capitalize
                }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-divider :key="index" v-if="index < messages.length"></v-divider>
          </template>
        </div>
        <v-divider></v-divider>
        <div class="text-center pt-2 show-all">
          <router-link :to="{ name: 'account_messages' }">{{
            $t("ShowAll")
          }}</router-link>
        </div>
      </template>

      <div
        v-if="!error && (!messages || messages.length == 0)"
        class="text-center pt-5 pb-5"
      >
        {{ $t("NoMessages") }}
      </div>
      <div v-if="error" class="text-center error-text pt-2 pb-2">
        {{ error }}
      </div>
    </v-list>
  </v-skeleton-loader>
</template>

<i18n>
    {
    "en": {
    "Last": "Last",
    "NewMessage": "New message",
    "NoMessages": "No messages to show!",
    "ShowAll": "Show all messages"
    },
    "sv": {
    "Last": "Senaste",
    "NewMessage": "Nytt meddelande",
    "NoMessages": "Inga meddelanden att visa",
    "ShowAll": "Visa alla meddelanden"
    }
    }
</i18n>

<script>
import { mapState, mapActions } from "vuex";
import UserAvatar from "@/components/avatars/UserAvatar.vue";
export default {
  data: () => ({}),
  computed: {
    ...mapState({
      user: (state) => state.account.user,
      is_loading: (state) => state.account.messages.inbox.is_loading,
      current_page: (state) => state.account.messages.inbox.current_page,
      has_new_data: (state) => state.account.messages.inbox.has_new_data,
      error: (state) => state.account.messages.inbox.error,
      messages: (state) => state.account.messages.inbox.list,
    }),
  },
  components: {
    "user-avatar": UserAvatar,
  },
  created() {
    this.load();
  },
  methods: {
    ...mapActions({
      inbox: "account/messages/inbox",
    }),

    load() {
      var self = this;

      if (this.has_new_data) {
        this.$store.dispatch("account/messages/reset", null, {
          root: true,
        });
      }

      if (self.is_loading || self.current_page > 0) return;

      this.inbox();
    },
    errorImg(item) {
      item.sender.avatar = null;
    },
  },
};
</script>

<style scoped>
.sub-header {
  height: auto !important;
  padding-bottom: 4px;
}
.sub-header a:hover {
  text-decoration: underline !important;
}

.show-all a {
  text-decoration: none;
  font-size: 14px;
}
.show-all a:hover {
  text-decoration: underline;
}

.message-container {
  max-height: 400px;
  overflow-y: auto;
}
</style>